.product-container {
  display: flex;
  flex-direction: column;
}

.product-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.detail {
  margin-bottom: 20px;
  font-size: 1.25em;
}

.title {
  font-weight: 600;
}

.bundle-product-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.bundle-product-description {
  font-weight: bold;
  font-size: 16px;
}
