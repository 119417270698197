@import '../../styles/colors';

.change-password {
  width: 50%;
  margin: 0 auto;

  .error {
    margin: 7px;
    height: 10px;
    color: $errorText;
  }
}
