.product-form {
  display: flex;
  flex-direction: row;
  width: 100%;

  .product-form-row {
    width: 100%;
  }

  .error-text {
    color: red;
    padding-left: 8px;
  }
}
