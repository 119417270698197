.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.form-row {
  width: 100%;
  margin-top: 25px;
}

.form-button {
  width: 175px;
}
