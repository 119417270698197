@import './src/styles/colors';

.remove-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.remove-button:hover {
  cursor: pointer;
  font-weight: 600;
}

.remove-label {
  font-size: 1em;
  color: red;
  margin-left: 5px;
}
