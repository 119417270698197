.product-prices {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0px;
  min-height: 3em;

  .product-prices-header {
    display: flex;
    align-items: center;

    h3 {
      margin-right: 7px;
    }
  }

  .price-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}
