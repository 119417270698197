.add-subscription {
  display: flex;
  flex-direction: column;
  width: 100%;

  .add-subscription-header {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 7px;
      font-weight: 600;
    }
  }

  .subscription-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    h2 {
      margin-right: 7px;
      font-weight: 600;
    }
    .cancel-button {
      transform: translateY(10px);
      margin-left: 15px;
    }
  }

  .add-payment {
    margin-top: 35px;
    min-height: 35px;

    .add-button {
      transform: translateY(-7px);
    }
  }

  .add-new {
    min-height: 35px;
  }
}

.MuiFormControl-root {
  // Need this to overwrite the MUI styles
  display: block !important;
}
