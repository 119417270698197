.dashboard-container {
  line-height: 1.3;

  h2 {
    margin-bottom: 5px;
  }

  ul {
    list-style: disc;
    margin-top: 10px;
    margin-left: 20px;
  }

  li {
    margin-bottom: 2px;
  }

  a {
    text-decoration: underline;
  }
}
