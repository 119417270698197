@import './src/styles/colors';

.title {
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}

.name {
  font-weight: 600;
  font-size: 1.25em;
  display: inline-block;
  margin-right: 10px;
}

.customer-contact {
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 20px;
}

.customer-view {
  display: flex;
  flex-direction: column;
  width: 100%;

  .customer-header {
    display: flex;
    margin-bottom: 7px;
    align-items: center;
  }

  .payment-method {
    padding: 15px 0px;
  }

  .subscriptions {
    flex-direction: column;

    .subscriptions-current,
    .subscriptions-new {
      min-height: 1.5em;
    }
  }
}

.customer-edit-button {
  margin-left: 20px;
}

.add-item-container {
  display: flex;
  align-items: center;
}
