@import '../../styles/colors';

.signin {
  width: 50%;
  margin: 0 auto;

  .auth-error-text {
    margin: 12px 0px;
    font-style: italic;
    color: $errorText;
  }
}
