.products-grid {
  width: 100%;
  margin-bottom: 15px;
}

.product-grid-header {
  display: flex;

  h3 {
    margin-right: 7px;
  }
}
