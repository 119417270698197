.quick-search {
  display: flex;
  flex-direction: row;
  width: 60%;
  position: relative;

  .quick-search-progress {
    padding-top: 2px;
    margin-left: 10px;
    height: 1em;
    position: absolute;
    top: 20px;
    left: -32px;
  }
}

.quick-search-suggestion {
  display: inline-grid;
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 15px;
  width: 100%;
}

.react-autosuggest__container {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 2em;
  padding: 2px 10px;
  font-size: 1.2em;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  transition: transform 200ms;
  transform: scaleY(0);
  transform-origin: top;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 44px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 1em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-shadow: 0 4px 4px grey;
  transition: transform 200ms;
  transform: scaleY(1);
  transform-origin: top;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
