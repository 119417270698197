.dashboard {
  display: flex;
  order: 1;

  .content {
    padding-left: 20px;
    padding-top: 40px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
}
