@import './src/styles/colors';

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.add-button:hover {
  cursor: pointer;
  font-weight: 600;
}

.add-button-label {
  font-size: 0.75em;
  margin-left: 5px;
  color: $green;
}
