.payment-methods {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0px;
  min-height: 3em;

  .payment-methods-header {
    display: flex;
    align-items: center;

    h3 {
      margin-right: 7px;
    }
  }

  .payment-method-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    flex-wrap: wrap;

    .iframe-container {
      overflow: hidden;
      position: relative;
    }

    .iframe-container iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .cancel-button {
    transform: translateY(10px);
    margin-left: 15px;
  }

  .error-message-container {
    margin-top: 10px;
  }

  .error-message {
    font-size: 0.75em;
    margin: 0;
  }
}
