$black: #000;
$white: #fff;
$brand-primary: #262a82;
$brand-secondary: #2196f3;
$brand-secondary-dark: #0069c0;
$errorText: #d50000;

$light-grey: #ddd;
$primary-grey: #565656;
$green: #388e3c;
$light-green: #4caf50;
$dark-green: #2e7d32;
$link-hover: #bababa;
