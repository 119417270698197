@import './src/styles/colors';

.action-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action-button-label {
  margin-left: 10px;
  cursor: pointer;
}
