@import './src/styles/colors';

.header {
  background: $brand-primary;
  color: $white;
  width: calc(100% - 3em);
  padding: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .logo-container {
    max-width: 180px;
  }

  .logo-image {
    width: 100%;
    height: auto;
  }

  .nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .nav-item {
    margin-left: 20px;
  }

  .nav-item:hover {
    cursor: pointer;
    color: $link-hover;
  }
}
