.invoices {
  position: relative;
}

.right-aligned {
  .MuiButton-text {
    width: 100%;
  }
}

.text-right {
  margin-right: 40px;
  text-align: right;
}

.total-balance {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
}