@import './src/styles/colors';

.list-item-link {
  text-decoration: none;
  color: $white;
}

a,
a:active {
  text-decoration: none;
}

.active-link-color {
  color: $link-hover;
}

.menu-item {
  color: white;
}

.external-links-header {
  cursor: default;
}
